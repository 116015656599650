<div
  class="w-full px-3 h-[130px] overflow-hidden bg-gray-800 dark:text-secondary-100"
>
  <app-header-menu-drawer />
  <div
    class="input-slim-mdc slim-fill"
    matTooltip="Disabled when edit mode is active."
    [matTooltipDisabled]="!editMode"
  >
    <mat-form-field appearance="fill" class="input-slim">
      <span matPrefix><i class="fal fa-search text-secondary-300"></i></span>
      <input
        [ngClass]="{ 'input-placeholder-disabled': editMode }"
        class="dark:text-secondary-100 !caret-white"
        type="text"
        matInput
        id="search-dashboard"
        data-testid="search-dashboard"
        maxlength="55"
        autocomplete="off"
        placeholder="search"
        [(ngModel)]="search"
        (keyup)="searchMenu()"
        [disabled]="editMode"
      />
      <button
        matSuffix
        mat-icon-button
        (click)="search = ''; searchMenu()"
        matTooltip="Clear"
        type="button"
        class="search-clear-button"
        [ngClass]="{
          invisible: !search.trim()
        }"
      >
        <i class="fal fa-xmark !text-secondary-300"></i>
      </button>
    </mat-form-field>
  </div>
</div>

<div
  class="w-full h-[calc(100%-190px)] section-menu scrollbar bg-gray-800 dark:text-secondary-100"
  [ngClass]="{
    'sm:h-[calc(100%-180px)]': isArchitect(),
    'sm:h-[calc(100%-130px)]': !isArchitect()
  }"
>
  <!-- Show navigation tabs. -->

  <section class="flex flex-col gap-2">
    <app-tabs-navigation-menu /> <mat-divider class="bg-gray-400"></mat-divider>
  </section>

  <section
    class="flex flex-col gap-2"
    id="bookmarked-section"
    data-testid="bookmarked-section"
    [ngClass]="{
      'animate-pulse': isSearching,
      hidden:
        bookmarkedListComponent &&
        bookmarkedListComponent.dashboardsList &&
        !bookmarkedListComponent.dashboardsList.length &&
        bookmarkedListComponent.folderDashboardData &&
        !bookmarkedListComponent.folderDashboardData.length
    }"
  >
    <app-list-board-menu-drawer
      #bookmarkedList
      [dashboardRole]="'bookmarked'"
      [editMode]="editMode"
    />
    <mat-divider class="bg-gray-400"></mat-divider>
  </section>

  <section
    class="flex flex-col gap-2"
    [ngClass]="{ 'animate-pulse': isSearching }"
  >
    <app-list-board-menu-drawer
      #companyList
      [dashboardRole]="dashboardRole.Company"
      [editMode]="editMode"
    />
    <mat-divider class="bg-gray-400"></mat-divider>
  </section>

  <section [ngClass]="{ 'animate-pulse': isSearching }">
    <app-list-board-menu-drawer
      #personalList
      [dashboardRole]="dashboardRole.Personal"
      [editMode]="editMode"
      [flagManagementMembersUpdate]="flagManagementMembersUpdate"
    />
  </section>
</div>
<footer
  class="footer h-[60px] sm:h-[50px] flex items-center p-2 border-t border-primary-200 bg-gray-800"
  [ngClass]="{ 'sm:hidden': !isArchitect() }"
>
  @if (isArchitect()) {
    @if (!editMode) {
      <div class="hidden sm:flex">
        <button
          mat-button
          type="button"
          id="edit-dashboard"
          (click)="enableEditMode()"
          data-testid="edit-dashboard"
        >
          <i class="fa-light fa-gear text-sm text-gray-400"></i>
          <span class="text-sm px-1 dark:text-secondary-200 text-white"
            >Edit {{ termsGeneric.Board.Title.Plural }}</span
          >
        </button>
      </div>
    } @else {
      <div class="w-full hidden sm:flex items-center gap-3 justify-end">
        <button
          mat-button
          type="button"
          (click)="confirmCancelEditMode()"
          id="cancel-edit-mode"
          data-testid="cancel-edit-mode"
          class="text-base"
        >
          <span class="dark:text-secondary-200 text-sm text-white">CANCEL</span>
        </button>
        <button
          mat-button
          type="button"
          (click)="saveEditMode()"
          id="save-edit-mode"
          data-testid="save-edit-mode"
          class="btn-save"
        >
          <span class="text-sm text-white"> SAVE</span>
        </button>
      </div>
    }
  }

  @if (currentUser) {
    <div class="w-full flex sm:hidden items-center justify-between">
      <div class="flex items-center space-x-4">
        <!-- Profile picture -->
        <div
          class="w-10 h-10 bg-white rounded-full"
          id="profile-button"
          data-testid="profile-button"
        >
          <app-user-avatar
            class="user-avatar"
            [firstName]="currentUser.firstName"
            [lastName]="currentUser.lastName"
            [hideToolTip]="true"
            [backgroundColor]="'#e5e7eb'"
            [navProfile]="true"
            [size]="'40'"
            [profileImageRithmId]="currentUser.profileImageRithmId || ''"
          />
        </div>
        <div class="flex flex-col text-sm w-[230px] truncate">
          <p class="text-base text-white">
            {{ currentUser.firstName }} {{ currentUser.lastName }}
          </p>
          <p class="text-xs text-gray-400">{{ currentUser.email }}</p>
        </div>
      </div>
      <!-- Options button (three dots) -->
      <button [matMenuTriggerFor]="userMenu" mat-icon-button type="button">
        <i class="fa-light text-lg fa-ellipsis-h text-gray-400"></i>
      </button>
    </div>
    <mat-menu
      #userMenu="matMenu"
      xPosition="before"
      class="mat-menu-user w-[225px] h-[120px]"
    >
      <button
        mat-menu-item
        type="button"
        routerLink="settings/account"
        data-testid="my-account"
        id="my-account"
        (click)="sidenavDrawerService.closeSidenav()"
      >
        My Account
      </button>
      <button
        mat-menu-item
        type="button"
        (click)="signOut()"
        data-testid="sign-out"
        id="sign-out"
      >
        Sign Out
      </button>
    </mat-menu>
  }
</footer>
